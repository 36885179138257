import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminService from "../../../components/services/admin.service";
import "../../../assets/styles/admin/datatable.css";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import DelBin from "../../../assets/images/myregisteration/trash-fill.svg";
import View from "../../../assets/images/successfulcards/view-icon.svg";
import { navigate } from "gatsby";

const CompanyDataTable = () => {
  const [customers, setCustomers] = useState();
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    contact_number: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    gender: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    first_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    email_address: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    payment_status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    payment_failed_reason: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    payment_date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  // const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [viewSelectedData, setviewSelectedData] = useState();

  const adminServices = new AdminService();

  useEffect(() => {
    adminServices.listcompanypartisipant().then((data) => {
      setCustomers(data?.data?.data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleViewClose = () => {
    setShowViewModal(false);
  };

  const approveParticipant = () => {
    adminServices.approveParticipant().then((res) => {
      if (res.data.isError === false || res.data.statusCode === "200") {
        toast.success(res?.data?.message);
      } else {
        if (res.data.isError === true) {
          toast.error(res?.data?.message);
        }
      }
    });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <span>
          <button
            class="btn btn-primary mx-1"
            onClick={() => navigate("/company/upload")}
          >
            Import
          </button>
          <button class="btn btn-primary" onClick={() => approveParticipant()}>
            Approve
          </button>
        </span>
      </div>
    );
  };

  const orderListCall = () => {
    adminServices.listcompanypartisipant().then((data) => {
      setCustomers(data?.data?.data);
    });
  };

  const confirmationPopup = (data) => {
    setSelectedCompany(data);
    setOpenModal(true);
  };
  const viewData = (data) => {
    setShowViewModal(true);
    setviewSelectedData(data);
  };

  const buttonTemplate = (data) => {
    return (
      <>
        {data?.status !== "confirmed" && (
          <span
            type="button"
            role="button"
            tabIndex="0"
            onKeyDown={(e) => confirmationPopup(data)}
            onClick={(e) => confirmationPopup(data)}
            title="Delete"
          >
            <img className="img-fluid" src={DelBin} alt="Clock logo" />
          </span>
        )}
        <span
          type="button"
          role="button"
          tabIndex="0"
          className="ml-1"
          onKeyDown={() => viewData(data)}
          onClick={() => viewData(data)}
          title="view"
        >
          <img className="img-fluid" src={View} alt="Clock logo" />
        </span>
      </>
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const dateBodyTemplate = (rowData) => {
    if (rowData?.payment_date !== null) {
      return moment(rowData?.payment_date).format("DD-MM-YYYY HH:MM A");
    }
  };

  // const reasonTemplate = (rowData) => {
  //   if(rowData?.payment_status === "ORDER_FAILED"){
  //     return rowData?.payment_failed_reason;
  //   }
  // }

  const header = renderHeader();

  const deleteParticipant = (data) => {
    adminServices
      .deleteParticipantById(data?.participant_id)
      .then((res) => {
        setOpenModal(false);
        toast.success(res?.data?.message?.[0]);
        orderListCall();
      })
      .catch((err) => {
        setOpenModal(false);
        toast.error(err?.data?.message?.[0]);
      });
  };

  return (
    <div className="datatable-doc-demo">
      <div className="card">
        <DataTable
          value={customers}
          paginator
          className="p-datatable-customers"
          header={header}
          rows={15}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[15, 30, 50]}
          dataKey="id"
          rowHover
          selection={selectedCustomers}
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          filters={filters}
          filterDisplay="menu"
          // loading={loading}
          responsiveLayout="scroll"
          stripedRows
          size="small"
          emptyMessage="No Records found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column
            field="first_name"
            header="Name"
            sortable
            style={{ width: "15rem" }}
            filter
            filterPlaceholder="Search by Name"
          />
          <Column
            field="email_address"
            header="Email"
            sortable
            style={{ width: "25rem" }}
            filter
            filterPlaceholder="Search by Email"
          />
          <Column
            field="gender"
            header="Gender"
            sortable
            style={{ width: "15rem" }}
            filter
            filterPlaceholder="Search by PNR Number"
          />

          <Column
            field="contact_number"
            header="Contact Number"
            sortable
            filter
            style={{ width: "15rem" }}
          />
          <Column
            field="payment_date"
            header="Date"
            sortable
            filterField="payment_date"
            dataType="date"
            style={{ minWidth: "15rem" }}
            body={dateBodyTemplate}
            filter
            filterElement={dateFilterTemplate}
          />
          <Column
            field="status"
            header="Status"
            sortable
            filter
            style={{ width: "15rem" }}
          />
          <Column
            header="Actions"
            headerStyle={{ width: "10rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={buttonTemplate}
          />
        </DataTable>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
      <Modal
        show={openModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="register-popup"
      >
        <Modal.Header closeButton onClick={handleClose}></Modal.Header>
        <Modal.Body>
          <h4> Are you sure want to delete ?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="whiteColor" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => deleteParticipant(selectedCompany)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showViewModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="register-popup"
      >
        <Modal.Header closeButton onClick={handleViewClose}>
          <Modal.Title id="contained-modal-title-vcenter">
            Participant Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {viewSelectedData && (
            <table className="participant_table">
              <tbody>
                <tr className="table-row">
                  <td className="body-cell">Name</td>
                  <td className="body-cell">{viewSelectedData?.first_name}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Gender</td>
                  <td className="body-cell">{viewSelectedData?.gender}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Blood group</td>
                  <td className="body-cell">{viewSelectedData?.blood_group}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">DOB</td>
                  <td className="body-cell">
                    {moment(viewSelectedData && viewSelectedData?.dob).format(
                      "DD-MM-YYYY"
                    )}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">T-shirt Size</td>
                  <td className="body-cell">
                    {viewSelectedData?.t_shirt_size}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Contact Number</td>
                  <td className="body-cell">
                    {viewSelectedData?.contact_number}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Email Id</td>
                  <td className="body-cell">
                    {viewSelectedData?.email_address}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Address</td>
                  <td className="body-cell">{viewSelectedData?.address}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Accomodation</td>
                  <td className="body-cell">
                    {viewSelectedData?.overnight_accommodation === "Y"
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">BIB collection location</td>
                  <td className="body-cell">
                    {viewSelectedData?.bib_collection_location}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Race Price</td>
                  <td className="body-cell">
                    ₹ {viewSelectedData?.race_price}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Company Code</td>
                  <td className="body-cell">
                    {viewSelectedData?.company_code}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompanyDataTable;
